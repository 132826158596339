import { inject } from '@angular/core';
import { AwsRum, type AwsRumConfig } from 'aws-rum-web';
import { createInjectable } from 'ngxtension/create-injectable';
import { UserContextService } from '../user-context/user-context.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, map, type Observable, tap } from 'rxjs';
import { signalSlice } from 'ngxtension/signal-slice';

const initialState: {
  awsRum: AwsRum | undefined;
} = {
  awsRum: undefined,
};

export const RUMService = createInjectable(() => {
  const userContextService = inject(UserContextService);

  const rumCredentials$ = toObservable(userContextService.credentials).pipe(filter(Boolean));

  const config: AwsRumConfig = {
    allowCookies: false,
    sessionSampleRate: 1,
    pageIdFormat: 'PATH',
    sessionAttributes: {
      displayResolution: `${window.screen.width}x${window.screen.height}`,
    },
    telemetries: [
      ['http', { urlsToInclude: [new RegExp(/.*\/api\/.*/), new RegExp(/\.dzi$/)] }],
      ['performance', { ignore: (entry: PerformanceEntry) => entry.entryType === 'resource' }],
      'error',
    ],
    recordResourceUrl: false,
    disableAutoPageView: true,
  };

  const awsRum$ = rumCredentials$.pipe(
    map((credentials) => {
      const awsRum = new AwsRum(credentials.appMonitorId, '0.0.0', 'eu-west-1', config);

      awsRum.setAwsCredentials({
        accessKeyId: credentials.accessKey,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      });

      awsRum.enable();

      return { awsRum };
    }),
  );

  const state = signalSlice({
    initialState,
    sources: [awsRum$],
    actionSources: {
      cleanup: (current, $: Observable<void>) =>
        $.pipe(
          tap(() => {
            current().awsRum?.dispatch();
            current().awsRum?.disable();
          }),
          map(() => ({ awsRum: undefined })),
        ),
    },
  });

  return {
    awsRum: state.awsRum,
    cleanup: state.cleanup,
  };
});
